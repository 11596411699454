.App {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-container {
  padding: 20px;
  margin: auto;
  width: calc(100vw - 270px);
}

a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  text-decoration: none;
  color: inherit;
}

[data-amplify-authenticator] {
  margin: 5% auto 50px;
}

fieldset {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
  position: relative;
}
