.wrapper-class {
  /*padding: 1rem;*/
  /*border: 1px solid #ccc;*/
}
.toolbar-class {
  border: 5px solid #ccc;
  margin-bottom: 0;
}
.editor-class {
  /*background-color: lightgray;*/
  border: 1px solid #ccc;
  padding: 0 0.8rem;
}
